import React from "react";
import { Helmet } from "react-helmet";

import "./layout.css";

const Layout = ({ children }) => (
  <main>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Karol Danko - about me</title>
    </Helmet>
    {children}
  </main>
);

export default Layout;
