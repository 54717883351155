import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";

const content = (
  <>
    <div className="centerContent">
      <ul>
        <li>
          Karol Danko: <a href="tel:+420730930979">+420 730 930 979</a>
        </li>
        <li>
          Anne Sünnen: <a href="tel:+420778895607">+420 778 895 607</a>
        </li>
      </ul>
    </div>
    <div className="centerContent">
      <ul>
        <li>
          Marek Danko:<a href="tel:+421907197363">+421 905 932 120</a>
        </li>
        <li>
          Mari Fabó:<a href="tel:+4219059321208">+421 905 932 120</a>
        </li>
        <li>
          Zuzana Ritoková CZ: <a href="tel:+42073482282">+420 734 822 823</a>
        </li>
        <li>
          Zuzana Ritoková SK: <a href="tel:+421915155805">+421 915 155 805</a>
        </li>
      </ul>
    </div>
    <div className="centerContent">
      <ul>
        <li>
          Matthias Suennen: <a href="tel:+4915115134913">+49 151 151 34913</a>
        </li>
        <li>
          Huberta Suennen: <a href="tel:+491741324620">+49 174 132 4620</a>
        </li>
        <li>
          Uwe Suennen: <a href="tel:+491759103712">+49 175 910 3712</a>
        </li>
        <li>
          Denny Suennen: <a href="tel:+4915206247644">+49 152 06 24 76 44</a>
        </li>
      </ul>
    </div>
  </>
);

const IndexPage = () => (
  <Layout>
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
    <div className="centerContent">
      <h1
        onClick={e => {
          e.target.parentNode.innerHTML = content.render();
        }}
      >
        Emergency contacts page
      </h1>
    </div>
    {content}
  </Layout>
);

export default IndexPage;
